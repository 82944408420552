import { Props as NavElementProps } from 'components/layout/NavElement';

import Component from './component';

export interface Props {
    hoverBorderVisible?: boolean;
    elements?: NavElementElement[];
    variant?: Variants;
    logoAlt?: string;
    onCloseNav?: (key?: string) => any;
    label?: string;
    setButtonGroupActiveKey?: Function;
    buttonGroupActiveKey?: string;
    onClickElement?: Function;
    userEmail?: string;
}

export interface NavElementElement extends NavElementProps {
    children?: any;
    key: string;
}

export enum Variants {
    Dark = 'dark',
    Light = 'light',
}

export enum ButtonKeys {
    Offers = 'offers',
    WorkerBlog = 'howToFindJob',
}

Component.defaultProps = {
    hoverBorderVisible: false,
    elements: [],
    variant: Variants.Light,
    onCloseNav: () => null,
    setButtonGroupActiveKey: () => null,
    buttonGroupActiveKey: null,
    label: null,
    onClickElement: null,
};
export default Component;