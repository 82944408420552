export enum Routes {
    // Portal routes
    PublicHome = '/',
    PublicAbout = '/o-nas',
    PublicCompany = '/pracodawca',
    PublicWorker = '/pracownik',
    PublicFaq = '/faq',
    PublicFaqCategory = '/faq/[slug]',

    PublicJobs = '/oferty-pracy',
    PublicJobsCity = '/oferty-pracy/[jobCompanyCitySlug]',
    PublicJobsCityCityDistrict = '/oferty-pracy/[citySlug]/[cityDistrict]',
    PublicJob = '/oferta-pracy/[slug]',
    PublicJobAplication = '/oferta-pracy/[slug]/[jobCitySlug]/formularz-aplikacyjny#application-form',
    PublicJobAplicationTYP = '/formularz-aplikacyjny-typ',

    PublicBlog = '/baza-wiedzy',
    PublicBlogCategory = '/baza-wiedzy/[articleCategorySlug]',
    PublicBlogPost = '/baza-wiedzy/[articleCategorySlug]/[slug]',
    PublicBlogCompany = '/strefa-wiedzy',
    PublicBlogCompanyPost = '/strefa-wiedzy/[slug]',

    PubicLogin = '/logowanie',
    PublicRegisterWorker = '/rejestracja-pracownik',
    PublicRegisterCompany = '/rejestracja-pracodawca',

    PublicLoginWorker = '/logowanie-pracownik',
    PublicLoginCompany = '/logowanie-pracodawca',

    PublicActivationAccount = '/aktywuj-konto',
    PublicAccountActivated = '/konto-aktywowane',

    PublicCompanySite ='/profil-firmowy/[slug]',
    PublicCompanySites ='/baza-pracodawcow',

    PublicComapnyPolicy = '/regulamin-pracodawcy',

    // Worker routes
    WorkerHome = '/admin',
    WorkerMe = '/admin/me',

    WorkerJobApplications = '/admin/job-applications',
    WorkerJobFavourites = '/admin/job-favourites',
    WorkerJobSearchQueries = '/admin/job-search-queries',
    WorkerMyDocuments = '/admin/documents',
    WorkerProfile = '/admin/worker-profile',

    WorkerConfig = '/admin/config',
    WorkerAccount = '/admin',

    // Company routes
    CompanyHome = '/admin',
    CompanyMe = '/admin/me',

    CompanyJobs = '/admin/jobs',
    CompanyJobsCreate = '/admin/jobs/create',
    CompanyJobsManage = '/admin/jobs/[id]',
    CompanySite = '/admin/site',
    CompanySiteConfirmation = '/admin/site/confirmation',
    CompanyIntegrations = '/admin/integrations-ats',

    CompanyPlans = '/admin/plans',

    CompanyCart = '/admin/cart',
    CompanyCartConfirmation = '/admin/cart/confirmation',

    CompanyConfig = '/admin/config',
	CompanyAccount = '/admin/jobs',
}