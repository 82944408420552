import User from 'models/User';

import { Props as NavSectionProps } from 'components/layout/NavSection';

import Component from './component';

export interface Props {
    navSectionProps: NavSectionProps;
    profile: User;
}

export default Component;
