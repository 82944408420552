import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(1 67 93 / 50%);
        z-index: 98;
    }

    .modal-container {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 65em;
        background-color: white;
        box-shadow: 0 0.4em 1em rgba(0,0,0,.05);
        z-index: 99;
        transform: translate(-50%, -50%);
        padding: 2em;

        .modal-header {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            text-align: left;

            .modal-header-title {
                font-weight: 700;
                font-size: 2em;
                color: #000000;
            }

            .modal-header-subtitle {
                font-weight: 700;
                font-size: 1.1em;
                color: #000000;
            }
        }

        .modal-header-close {
            position: absolute;
            top: 5%;
            right: 2%;
            cursor: pointer;
            padding: 0 .5em;
        }

        .modal-body {}
    }

    @media all and (max-width: ${variables.mobileL}) {
        .modal-container {
            position: fixed;
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            transform: none;
        }
    }
`;