import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Button, { ButtonStyles, ButtonVariants } from 'components/layout/Button';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutButtonGroup: FunctionComponent<Props> = ({ buttons, activeKey, onSetActiveKey }) => {
    return (
        <StyledComponent className="layout-button-group">
            {buttons.map((button, buttonIndex) => {
                const isFirst = buttonIndex === 0;
                const isLast = buttonIndex >= buttons.length - 1;

                return (
                    <Button
                        key={button.key}
                        className={classnames(
                            'button-group-element',
                            {
                                first: isFirst,
                                last: isLast,
                                active: activeKey === button.key,
                                unactive: activeKey !== button.key,
                            }
                        )}
                        styles={{
                            zIndex: 98 - buttonIndex,
                        }}
                        onClick={() => onSetActiveKey(button.key)}
                        style={ButtonStyles.Primary}
                        variant={activeKey !== button.key && ButtonVariants.Outline || ButtonVariants.Primary}
                        {...button}
                    />
                );
            })}
        </StyledComponent>
    );
};

export default LayoutButtonGroup;