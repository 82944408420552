import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import Button, { ButtonElements, ButtonStyles } from 'components/layout/Button';
import ButtonGroup from 'components/layout/ButtonGroup';
import SubMenu from 'components/layout/NavElementSubMenu';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutNavElement: FunctionComponent<Props> = ({
    buttonProps,
    buttonGroupProps,
    variant,
    subMenu,
    hoverBorderVisible,
    active,
    onClickTitle,
    onSubmenuClick,
    setButtonGroupActiveKey,
    buttonGroupActiveKey,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-nav-element',
                `variant-${variant}`,
                {
                    active,
                    'with-sub-elements': subMenu,
                }
            )}
        >
            {buttonProps && (
                <Button
                    className={classnames({ 'current': active })}
                    onClick={onClickTitle}
                    element={ButtonElements.Anchor}
                    style={ButtonStyles.None}
                    {...buttonProps}
                />
            )}
            {buttonGroupProps && (
                <ButtonGroup
                    activeKey={buttonGroupActiveKey}
                    onSetActiveKey={nextActiveKey => setButtonGroupActiveKey(nextActiveKey)}
                    buttons={buttonGroupProps.buttons}
                />
            )}
            {hoverBorderVisible && (<span className="hover-border" />)}
            {subMenu && (
                <div className="sub-nav-elements-container">
                    <SubMenu
                        {...subMenu}
                        onSubmenuClick={onSubmenuClick}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutNavElement;