import { FunctionComponent } from 'react';

import x from 'theme/icons/x.svg';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutModal: FunctionComponent<Props> = ({ children, title, subtitle, onClose, alt }) => {
    return (
        <StyledComponent
            className="layout-modal"
        >
            <div
                className="modal-backdrop"
                onClick={onClose}
            />
            <div className="modal-container">
                <div className="modal-header">
                    {title && (
                        <h3
                            className="modal-header-title"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    {subtitle && (
                        <div
                            className="modal-header-subtitle"
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                        />
                    )}
                </div>

                <div className="modal-body">
                    {children}
                </div>
                <a
                    className="modal-header-close"
                    onClick={onClose}
                >
                    <img
                        className="icon"
                        src={x}
                        alt={alt}
                        loading="lazy"
                    />
                </a>
            </div>
        </StyledComponent>
    );
};

export default LayoutModal;