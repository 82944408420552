import { ReactNode } from 'react';

import Component from './component';

export interface Props {
    title?: string;
    subtitle?: string;
    chidren?: ReactNode;
    alt?: string;
    onClose: () => any;
}

Component.defaultProps = {
    title: null,
    subtitle: null,
    chidren: null,
};

export default Component;