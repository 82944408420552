import { FunctionComponent, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Tracking, { SmEventDescriptons, SmEventTypes } from 'services/Tracking';

import NavElement from 'components/layout/NavElement';

import { ButtonKeys, Props } from './index';
import StyledComponent from './styles';

const LayoutNavSection: FunctionComponent<Props> = ({ elements, variant, hoverBorderVisible, onCloseNav, setButtonGroupActiveKey, buttonGroupActiveKey, onClickElement, userEmail }) => {
    const [selectedKey, setSelectedKey]: [string, Function] = useState(null);

    const { pathname } = useRouter();


    return (
        <StyledComponent
            className={classnames(
                'layout-nav-section',
                `variant-${variant}`,
            )}
        >
            {elements
                .map(element => {
                    return (
                        <>
                            <NavElement
                                key={element.key}
                                onClickTitle={() => {
                                    selectedKey === element.key
                                        ? setSelectedKey(null)
                                        : setSelectedKey(element.key);

                                    onClickElement && onClickElement();

                                    if (userEmail) {
                                        element.key === ButtonKeys.WorkerBlog && Tracking.salesManago(
                                            userEmail,
                                            SmEventTypes.Other,
                                            SmEventDescriptons.BlogWorker,
                                            window.location?.toString(),
                                        );
                                        element.key === ButtonKeys.Offers && Tracking.salesManago(
                                            userEmail,
                                            SmEventTypes.Other,
                                            SmEventDescriptons.JobsTab,
                                            window.location?.toString(),
                                        );
                                    }
                                }}
                                variant={variant}
                                hoverBorderVisible={hoverBorderVisible}
                                active={pathname.startsWith(element.buttonProps.href)}
                                onSubmenuClick={onCloseNav}
                                setButtonGroupActiveKey={setButtonGroupActiveKey}
                                buttonGroupActiveKey={buttonGroupActiveKey}
                                {...element}
                            />

                        </>
                    );
                })}
        </StyledComponent>
    );
};

export default LayoutNavSection;