import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import Button from 'components/layout/Button';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutNavElementSubMenu: FunctionComponent<Props> = ({ layout, sections, onSubmenuClick }) => {
    return (
        <StyledComponent
            className={classnames(
                'layout-nav-element-sub-menu',
                `layout-${layout}`,
            )}
        >
            {sections.map(section => (
                <div
                    key={section.key}
                    className={classnames(
                        'sub-menu-section',
                        `type-${section.type}`,
                    )}
                >
                    {section.title && (
                        <div
                            className="sub-menu-section-title"
                            dangerouslySetInnerHTML={{ __html: section.title }}
                        />
                    )}
                    <div className="sub-menu-section-elements">
                        {section.elements.map(element => (
                            <Link
                                key={element.key}
                                href={element?.href || ''}
                                prefetch={false}
                            >
                                <div
                                    className="sub-menu-section-element"
                                    onClick={() => onSubmenuClick(element.key)}
                                >
                                    <div className="element-header">
                                        <span
                                            className="sub-menu-section-element-title"
                                            dangerouslySetInnerHTML={{ __html: element.title }}
                                        />
                                        {element.subTitle && (
                                            <span
                                                className="sub-menu-section-element-sub-title"
                                                dangerouslySetInnerHTML={{ __html: element.subTitle }}
                                            />
                                        )}
                                        {element?.buttonProps && <Button {...element?.buttonProps} />}
                                    </div>
                                    {element?.icon && (
                                        <img
                                            className="sub-menu-section-element-icon"
                                            src={element?.icon}
                                            alt={element?.alt}
                                            loading="lazy"
                                        />
                                    )}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </StyledComponent>
    );
};

export default LayoutNavElementSubMenu;