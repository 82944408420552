import { FunctionComponent, useEffect, useState } from 'react';

import { Locales } from 'types/locale';

import { LS_SERVICE_WORK } from 'consts/localStorage';
import { select as selectLocale } from 'utils/locale';
import { checkInLocalStorage, createLocalStorage } from 'utils/localStorage';

import IconWarrning from 'theme/icons/warning.svg';

import Modal from 'components/layout/Modal';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutModalsServiceWorkModal: FunctionComponent<Props> = () => {
    const [modalVisible, setModalVisible]: [boolean, Function] = useState(false);

    const translations = selectLocale({
        [Locales.Pl]: require('./locales/pl.json'),
    });

    const closeModal = () => {
        setModalVisible((prevState:boolean) => !prevState);
        createLocalStorage(LS_SERVICE_WORK, 'displayed');
    };

    useEffect(() => {
        !checkInLocalStorage(LS_SERVICE_WORK) && setModalVisible(true);
    }, []);

    return (
        <StyledComponent className="public-layout-modals-service-work-modal">
            {modalVisible && (
                <Modal
                    {...translations?.modalProps}
                    onClose={closeModal}
                >
                    <div className="modal-wrapper">
                        <img
                            src={IconWarrning}
                            alt=""
                            loading="lazy"
                        />
                        <p
                            className="modal-content"
                            dangerouslySetInnerHTML={{ __html: translations?.modalContent }}
                        />
                    </div>
                </Modal>
            )}
        </StyledComponent>
    );
};

export default PublicLayoutModalsServiceWorkModal;