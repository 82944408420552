import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import User from 'models/User';

import { profileGet } from 'store/modules/user/actions';
import { State as ReduxState } from 'store/state';

import { Props as ButtonProps } from 'components/layout/Button';
import { Props as NavSectionProps } from 'components/layout/NavSection';

export enum ElementKeys {
    Profile = 'profile',
    Home = 'home',
}

export interface ElementProps extends Omit<ButtonProps, 'icon'> {
    key: string;
    icon: string;
    iconActive: string;
    alt: string;
    title: string;
    subMenu: ElementProps[],
}

import Component from './component';

export interface Props extends ParentProps {
    state: {
        authToken: string;
        profile: User;
    };
    actions: {
        logout: () => Promise<any>;
        reactivation: (params: object) => Promise<any>;
        profileGet: Function;
    };
}

export interface ParentProps {
    mobileBottomMenu: NavSectionProps;
}

const mapStateToProps = (state: ReduxState, ownProps?: Props): object => {
    return {
        state: {
            profile: state.user.profile,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            profileGet: bindActionCreators(profileGet, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);