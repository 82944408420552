import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        font-size: 1em;
        text-decoration: none;
        cursor: pointer;

        border-radius: .4em;
        border: none;
        min-height: 3.1em;
        &.disabled {
            background-color: #909090 !important;
        };
    }

    .button-shadow {
        position: absolute;
        width: 80%;
        height: 40%;
        left: 10%;
        top: 70%;
        z-index: 1;
        opacity: 1;
        filter: blur(.5em);
        display: none;
    }

    a {
        text-decoration: none;
    }

    span {
        font-size: 1.2em;
        text-decoration: none;
        cursor: pointer;
    }

    .button-icon-before {
        margin-right: .5em;
    }

    .button-icon-after {
        margin-left: .5em;
    }

    /* Layouts */
    &.layout-default {
        .button {
            padding: .7em 1.5em;
        }
    }
    &.layout-wide {
        .button {
            padding: .7em 2.5em;
        }
    }
    &.layout-extra-wide {
        .button {
            padding: .7em 4.5em;
        }
    }
    &.layout-block {
        width: 100%;

        .button {
            padding: .7em 1em;
            width: 100%;
        }

        a {
            width: 100%;
        }
    }


    /* Sizes */
    &.size-small {
        .button {
            font-size: .75em;
        }
    }
    &.size-medium {
        .button {
            font-size: .9em;
        }
    }
    &.size-large {
        .button {
            font-size: 1.15em;
        }
    }


    /* Styles */
    &.style-primary {
        &.variant-primary {
            .button {
                background-color: #10F5B0;
                color: #000000;
                font-weight: 700;

                &:hover {
                    background-color: #21DBA7;
                }
            }


        }
        &.variant-outline {
            .button {
                background-color: #ffffff;
                color: #000000;
                border: .01em solid #000000;
                box-shadow: none;

                .button-content {
                    position: relative;
                    padding: 0 .3em;
                }

                .button-content:after {
                    content: "";
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: .6em;
                    z-index: -1;
                    top: 20%;
                    left: 0;
                    background: #10F5B0;
                    animation-name: highlight;
                    animation-duration: 0.75s;
                }

                @keyframes highlight {
                    0% {
                        width: 0;
                        opacity: 0;
                    }

                    50% {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }

            &:hover {
                .button {
                    .button-content:after {
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.style-secondary {
        &.variant-primary {
            .button {
                background-color: #000000;
                color: #FFFFFF;
                font-weight: 700;
                box-shadow: none;

                &:hover {
                    background-color: #4D4D4D;
                }
            }
        }
        &.variant-outline {
            .button {
                position: relative;
                background-color: transparent;
                color: #000000;
                box-shadow: none;
                font-weight: 700;

                .button-icon-before-hover, .button-icon-after-hover {
                    display: none;
                    position: relative;
                }

                .button-icon-before, .button-icon-before-hover {
                    margin-right: .5em;
                }

                .button-icon-after, .button-icon-after-hover {
                    margin-left: .5em;
                }
            }

            &:hover {
                .button {
                    .button-icon-after, .button-icon-before {
                        display: none;
                    }

                    .button-icon-after-hover, .button-icon-before-hover {
                        display: block;
                    }
                }
            }
        }
    }

    &.style-light {
        &.variant-primary {
            .button {
                background-color: white;
                color: #00AEEF;
                border: white;
            }

            .button-shadow {
                background: white;
            }

            &:hover {
                .button {
                    background-color: #EEEEEE;
                }
            }
        }
        &.variant-outline {
            .button {
                background-color: transparent;
                color: #FFFFFF;
                border: .15em solid #FFFFFF;
                box-shadow: none;
            }

            .button-shadow {
                background: white;
            }

            &:hover {
                .button {
                    background-color: rgba(255, 255, 255, 0.2);;
                    color: white;
                }
            }
        }
    }

    &.style-gold {
        &.variant-primary {
            .button {
                background-color: #BA9449;
                color: white;
            }

            &:hover {
                .button {
                    background-color: #CFB799;
                }
            }
        }
        &.variant-secondary {
            .button {
                background-color: #CFB799;
                color: white;
                padding: .5em 1em;
                border-radius: .7em;
            }

            &:hover {
                .button {
                    background-color: #BA9449;
                }
            }
        }
    }

    &.style-success {
        &.variant-primary {
            .button {
                background-color: #85d033;
                color: white;
            }

            .button-shadow {
                background: #85d033;
            }
        }
        &.variant-outline {
            .button {
                background-color: transparent;
                color: #85d033;
                border: .01em solid #85d033;
            }

            .button-shadow {
                background: #85d033;
            }
        }
    }

    &.style-warning {
        &.variant-primary {
            .button {
                background-color: #ff954a;
                color: white;
            }

            .button-shadow {
                background: #ff954a;
            }
        }
        &.variant-outline {
            .button {
                background-color: transparent;
                color: #ff954a;
                border: .01em solid #ff954a;
            }

            .button-shadow {
                background: #ff954a;
            }
        }
    }

    &.style-danger {
        &.variant-primary {
            .button {
                background-color: #f72d2d;
                color: white;
            }

            .button-shadow {
                background: #f72d2d;
            }
        }
        &.variant-outline {
            .button {
                background-color: transparent;
                color: #EE6C4D;
                border: .01em solid #f72d2d;
            }

            .button-shadow {
                background: #f72d2d;
            }
        }
    }

    &.style-none {
        .button {
            background-color: transparent;
            color: black;
            box-shadow: none;
            padding: 0;
            min-height: unset;
        }

    }

    /* Modificators */
    &.disabled {
        .button {
            background-color: #666666 !important;
            color: white !important;
        }
    }

    &.shadow {
        .button-shadow {
            display: block;
        }
    }
    &.hover-button {
        font-weight: 700;
        .button-link {
            width: 100%;
        }
        .hover-container {
            position: relative;
            .hover-text {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                transition: all .5s ease-in-out;
            }
            .button-content {
                transition: all .5s ease-in-out;
            }
        }
        &:hover {
                .button {
                    .button-content {
                        &:after {
                           content: none;
                        }

                    }

                }
            }
        &.active {
                .button {
                    .button-content {
                        opacity: 0;

                    }
                    .hover-text {
                        opacity: 1;
                    }
                }
            }
    }
`;
