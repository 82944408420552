import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import ChevronDownIcon from 'theme/icons/chevron-down.svg';
import UserIcon from 'theme/icons/user.svg';

import NavSection from 'components/layout/NavSection';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutProfileBox: FunctionComponent<Props> = ({ navSectionProps, profile }) => {
    const [isExpanded, setIsExpanded]: [boolean, Function] = useState(false);

    return (
        <StyledComponent
            className="layout-profile-box"
            onMouseEnter={() => setIsExpanded(true)}
            onMouseLeave={() => setIsExpanded(false)}
        >
            <div className="panel">
                <div className="user">
                    <img
                        src={UserIcon}
                        className="icon"
                        alt={profile.fullName}
                        loading="lazy"
                    />
                    <span className="user-name">
                        {profile?.firstName}
                    </span>
                </div>
                <a className="expand-controls">
                    <img
                        src={ChevronDownIcon}
                        className={classnames(
                            'icon',
                            { rotate: isExpanded }
                        )}
                        loading="lazy"
                    />
                </a>
            </div>
            {isExpanded && (
                <div className="expandable">
                    <div className="profile-details">
                        <div className="profile-name">
                            <img
                                src={UserIcon}
                                className="icon"
                                alt={profile.fullName}
                                loading="lazy"
                            />
                            <span className="user-name">
                                {profile?.shortName}
                            </span>
                        </div>
                        <div className="profile-email">
                            <span className="user-email">
                                {profile?.email}
                            </span>
                        </div>
                    </div>
                    <NavSection {...navSectionProps} />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutProfileBox;