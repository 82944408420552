import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	border-top: 0.1em solid #cccacc;
	background-color: #ffffff;
	padding: 1.6em 3em;

	.menu-element {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.element-title {
			padding-top: 0.5em;

			&.active {
				font-weight: 600;
			}
		}
	}

	.submenu {
        position: absolute;
		top: -6em;
        background-color: ${vars.colorBackgroundContrast};
        border: 0.01em solid ${vars.colorBackgroundBorder};
        border-radius: 0.2em;
        padding: 1em;
        display: flex;
        .menu-element {
			&:not(:last-child) {
				margin-right: 1em;
			}
		}
	}
`;
