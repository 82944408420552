import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
    border: .01em solid #000000;
    overflow: hidden;

    .button-group-element {
        width: 100%;
        position: relative;
        z-index: 1;

        .button {
            width: 100%;
            font-size: .8em;
            font-weight: 300;
            padding: 1.08em 3.28em;
        }

        &.active {
            position: relative;
            z-index: 99 !important;

            .button {
                font-weight: 300;
            }
        }

        &.last {
            .button {
                padding-right: 3em;
            }
        }
    }
`;