import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    z-index: 2;

    .panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .user {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {

            }
            .user-name {
                font-size: .9em;
                font-weight: 600;
                margin-left: .5em;
            }
        }
        .expand-controls {
            margin-left: .5em;

            .icon {
                &.rotate {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .expandable {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 15em;
        padding-top: 1em;

        .profile-details {
            background-color: #000000;
            color: #FFFFFF;
            padding: 1.2em 2em;
            font-size: .9em;

            .profile-name {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon {
                    filter: invert(98%) sepia(100%) saturate(7%) hue-rotate(115deg) brightness(102%) contrast(102%);
                }

                .user-name {
                    margin-left: .8em;
                }
            }
            .profile-email {
                margin-left: 2.3em;
            }
        }

        .layout-nav-section {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            background-color: #FFFFFF;
            padding: .5em 1em;

            .layout-nav-element {
                width: 100%;
                padding: .6em 0;
                margin-right: 0;

                .layout-button {
                    justify-content: flex-start;
                }
            }
        }
    }
    @media(max-width:1300px) {
   .expandable {
    .layout-nav-section {
        .layout-nav-element {
            padding: 0;
        }
    }
   }
}
`;