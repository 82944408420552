import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Spinner, { Colors, Positions } from 'components/layout/Spinner';

import { ButtonElements, ButtonIconPositions, ButtonIconTypes, Props } from './index';
import StyledComponent from './styles';

const LayoutButton: FunctionComponent<Props> = ({
    children,
    href,
    target,
    onClick,
    type,
    element,
    className,
    icon,
    disabled,
    size,
    style,
    styles,
    variant,
    layout,
    loading,
    confirm,
    buttonProps,
    shadow,
    hoverText,
    rel,
    ariaLabel,
    badge,
}) => {
    const router = useRouter();

    const defaultConfirmProps = {
        message: 'Potwierdź swój wybór',
    };

    const onCheckClick = () => {
        if (disabled) {
            return;
        }

        if (confirm?.enabled) {
            const confirmConfig = { ...defaultConfirmProps, ...confirm };

            if (window.confirm(confirmConfig.message)) {
                return onClick && onClick();
            } else {
                return null;
            }
        }

        return onClick && onClick();
    };

    const getIcon = () => {
        if(!icon) return null;

        if(icon.type === ButtonIconTypes.Element) {
            return icon.value || null;
        }

        if(icon.type === ButtonIconTypes.Image) {
            return (
                <img
                    className="button-icon-image"
                    src={icon.value}
                    loading="lazy"
                    alt={icon.alt}
                    width={icon?.width}
                    height={icon?.height}
                />
            );
        }

        return null;
    };

    const getContent = () => {
        return loading
            ? (
                <Spinner
                    color={Colors.White}
                    position={Positions.Relative}
                />
            )
            : (
                <>
                    {icon && icon.position === ButtonIconPositions.Before && <div className="button-icon-before">{getIcon()}</div>}
                    {icon && icon.position === ButtonIconPositions.Before && icon?.hover && (
                        <div className="button-icon-before-hover">
                            <img
                                className="button-icon-image"
                                src={icon?.hover}
                                loading="lazy"
                                alt={icon.alt}
                                width={icon?.width}
                                height={icon?.height}
                            />
                        </div>
                    )}

                    {hoverText ? (
                        <div className='hover-container'>
                            <span className='hover-text'>{hoverText}</span>
                            <span
                                className="button-content"
                                dangerouslySetInnerHTML={{ __html: children }}
                            />
                        </div>
                    ) : (
                        <span
                            className="button-content"
                            dangerouslySetInnerHTML={{ __html: children }}
                        />
                    )}
                    {icon && icon.position === ButtonIconPositions.After && <div className="button-icon-after">{getIcon()}</div>}
                    {icon && icon.position === ButtonIconPositions.After && (
                        <div className="button-icon-after-hover">
                            <img
                                className="button-icon-image"
                                src={icon?.hover}
                                loading="lazy"
                                alt={icon.alt}
                                width={icon?.width}
                                height={icon?.height}
                            />
                        </div>
                    )}
                </>
            );
    };

    const getElement = () => {
        const button = (
            <button
                type={type}
                onClick={onCheckClick}
                disabled={disabled}
                className="button"
                aria-label={ariaLabel}
                {...buttonProps}
            >
                {getContent()}
            </button>
        );

        switch (element) {
            case ButtonElements.Anchor:
                if(href?.includes('#')) {
                    return (
                        <a
                            className='button-link'
                            href={href}
                            onClick={() => {
                                router.push(href);
                            }}
                            {...buttonProps}
                        >
                            {button}
                        </a>
                    );
                }
                if(href) {
                    return (
                        <>
                            <a
                                className='button-link'
                                href={href}
                                target={(target && target) || ''}
                                {...buttonProps}
                            >
                                {button}
                            </a>
                            {badge && (
                                <img
                                    src={badge}
                                    alt="badge"
                                />
                            )}
                        </>
                    );
                }
                return (
                    <div
                        className='button-link'
                        href={href}
                        rel={rel}
                        target={(target && target) || ''}
                        {...buttonProps}
                    >
                        {button}
                    </div>
                );
            default:
                return button;
        }
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-button',
                className,
                `element-${element}`,
                `size-${size}`,
                `style-${style}`,
                `variant-${variant}`,
                `layout-${layout}`,
                {
                    disabled,
                    shadow: shadow?.enabled,
                },
                {
                    'hover-button': hoverText,
                }
            )}
            style={styles}
        >
            {getElement()}
            {shadow?.enabled && (
                <div
                    className="button-shadow"
                    style={shadow.style || {}}
                />
            )}
        </StyledComponent>
    );
};

export default LayoutButton;