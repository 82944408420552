import { Props as ButtonProps } from 'components/layout/Button';
import { Props as ButtonGroupProps } from 'components/layout/ButtonGroup';
import { Props as SubMenuProps } from 'components/layout/NavElementSubMenu';

import Component from './component';

export interface Props {
    active?: boolean;
    onClickTitle?: Function;
    hoverBorderVisible?: boolean;
    buttonProps?: ButtonProps;
    buttonGroupProps?: ButtonGroupProps;
    variant?: Variants;
    subMenu?: SubMenuProps;
    onSubmenuClick?: (key?: string) => any;
    icon?: string;
    setButtonGroupActiveKey?: Function;
    buttonGroupActiveKey?: string;
}

export enum Variants {
    Dark = 'dark',
    Light = 'light',
}

Component.defaultProps = {
    active: false,
    onClickTitle: null,
    hoverBorderVisible: false,
    subMenu: null,
    buttonProps: null,
    buttonGroupProps: null,
    variant: Variants.Light,
    onSubmenuClick: () => null,
    setButtonGroupActiveKey: () => null,
    buttonGroupActiveKey: null,
    icon: null,
};
export default Component;