import styled from '@emotion/styled';

export default styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .layout-nav-element {
        margin-right: 1.47em;

        &:last-of-type {
            margin-right: 0%;
        }
    }
`;