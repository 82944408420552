import { ListProps as ButtonListProps } from 'components/layout/Button';

import Component from './component';

export interface Props {
    activeKey: string;
    buttons: ButtonListProps[];
    onSetActiveKey: (nextKey: string) => void;
}

Component.defaultProps = {};
export default Component;