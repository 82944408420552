import styled from '@emotion/styled';

export default styled.div`
    position: relative;

    .layout-button.element-button.variant-primary {
        .button-content {
            font-size: 92%;
        }

    }

    .layout-button.element-anchor {

        .button {
            color: black;
            transition: .2s color ease-in-out;

            .button-content {
                font-size: 92%;
            }
        }

        &:hover {
            .button {
                color: #EEEEEE;
            }

            .button-content {
                box-shadow: 0 .05em #FFFFFF;
            }
        }
    }

    .hover-border {
        position: absolute;
        width: 100%;
        height: .15em;
        top: 95%;
        background-color: #b2934a;
        z-index: 1;
        display: none;
    }

    &.with-sub-elements {
        position: relative;

        .sub-nav-elements-container {
            display: none;
            opacity: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            transition: .3s opacity ease-in-out;
            transform: translate(-50%);
        }

        &:hover {
            .sub-nav-elements-container {
                opacity: 1;
                display: block;
            }
        }
    }

    &.variant-light {
        .layout-button.element-anchor {
            .button {
                color: white;
            }
        }
    }

    &.variant-dark {
        .layout-button.element-anchor {
            .button {
                color: #000000;

                .button-content {
                    position: relative;
                    padding: 0 .3em;
                }

                .button-content:after {
                    content: "";
                    display: none;
                    position: absolute;
                    width: 100%;
                    height: .6em;
                    z-index: -1;
                    top: 20%;
                    left: 0;
                    background: #10F5B0;
                    animation-name: highlight;
                    animation-duration: 0.75s;
                }

                @keyframes highlight {
                    0% {
                        width: 0;
                        opacity: 0;
                    }

                    50% {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
            &:hover {
                .button {
                    .button-content:after {
                        display: inline-block;
                    }
                }
            }
            &.current {
                .button-content {
                    &:after {
                        display:inline-block;
                    }

                }
            }
        }

    }

    &:hover {
        .hover-border {
            display: block;
        }
    }
`;