import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	.layout-modal {
		.modal-container {
			max-width: 34em;
			border-bottom: 0.625em solid ${vars.colorWarning};
		}
	}

	.modal-wrapper {
		display: flex;
		flex-flow: column wrap;
		text-align: center;
		margin: 0 auto;

		.modal-content {
			padding: 1em;
			font-size: 1.18em;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		.layout-modal {
			.modal-container {
				max-width: unset;
				border-bottom: unset;
				top: 0;
				left: 0;
				width: 100%;
				height: 91%;
				padding: 8em 2em 0 2em;
				background-color: ${vars.colorBackground};

				.modal-header-close {
					top: 8%;
				}
			}
		}

		.modal-wrapper {
			.modal-content {
				font-size: 1.2em;
			}
		}
	}
`;
