import { FunctionComponent, ReactNode, useState } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Roles } from 'models/User';

import { ElementKeys, ElementProps, Props } from './index';
import StyledComponent from './styles';

const PublicLayoutNavigationMobileBottomMenu: FunctionComponent<Props> = ({ mobileBottomMenu, state }) => {
    const [subMenuVisible, setSubMenuVisible]: [boolean, Function] = useState(false);

    const router = useRouter();
    const { publicRuntimeConfig } = getConfig();
    const { profile } = state || {};

    const setMenuElement = (element: ElementProps, key: string): ReactNode => {
        switch (key) {
            case ElementKeys.Profile:
                return (
                    <div
                        className="menu-element"
                        key={element.key}
                        onClick={
                            () => {
                                profile
                                    ? profile?.role === Roles.Worker
                                        ? router.push(publicRuntimeConfig.WORKER_PANEL_URL + Routes.WorkerAccount)
                                        : router.push(publicRuntimeConfig.COMPANY_PANEL_URL + Routes.CompanyHome)
                                    : router.push(element.href);
                            }
                        }
                    >
                        <img
                            className="element-icon"
                            src={router.asPath === element.href
                                ? element?.iconActive
                                : element?.icon}
                            alt={element?.alt}
                            width="24"
                            height="24"
                            loading="lazy"
                        />
                        <span
                            className={classnames(
                                'element-title',
                                {
                                    active: router.asPath === element.href,
                                }
                            )}
                            dangerouslySetInnerHTML={{ __html: element?.title }}
                        />
                    </div>
                );
            case ElementKeys.Home:
                return (
                    <>
                        <div
                            key={element.key}
                            onClick={() => setSubMenuVisible(!subMenuVisible)}
                        >
                            <div className="menu-element">
                                <img
                                    className="element-icon"
                                    src={router.asPath === element.href
                                        ? element?.iconActive
                                        : element?.icon}
                                    alt={element?.alt}
                                    width="24"
                                    height="24"
                                    loading="lazy"
                                />
                                <span
                                    className={classnames(
                                        'element-title',
                                        {
                                            active: router.asPath === element.href,
                                        }
                                    )}
                                    dangerouslySetInnerHTML={{ __html: element?.title }}
                                />
                            </div>
                        </div>
                        {subMenuVisible && (
                            <div className="submenu">
                                {Array.isArray(element?.subMenu) && element?.subMenu.map((subElement) => (

                                    <div
                                        className="menu-element"
                                        key={subElement.key}
                                        onClick={() => router.push(subElement.href)}
                                    >
                                        <img
                                            className="element-icon"
                                            src={router.asPath === subElement.href
                                                ? subElement?.iconActive
                                                : subElement?.icon}
                                            alt={subElement?.alt}
                                            width="24"
                                            height="24"
                                            loading="lazy"
                                        />
                                        <span
                                            className={classnames(
                                                'element-title',
                                                {
                                                    active: router.asPath === subElement.href,
                                                }
                                            )}
                                            dangerouslySetInnerHTML={{ __html: subElement?.title }}
                                        />
                                    </div>

                                ))}
                            </div>
                        )}

                    </>
                );
            default:
                return (
                    <div
                        className="menu-element"
                        key={element.key}
                        onClick={() => router.push(element.href)}
                    >
                        <img
                            className="element-icon"
                            src={router.asPath === element.href
                                ? element?.iconActive
                                : element?.icon}
                            alt={element?.alt}
                            width="24"
                            height="24"
                            loading="lazy"
                        />
                        <span
                            className={classnames(
                                'element-title',
                                {
                                    active: router.asPath === element.href,
                                }
                            )}
                            dangerouslySetInnerHTML={{ __html: element?.title }}
                        />
                    </div>

                );
        }
    };

    return (
        <StyledComponent className="public-layout-navigation-mobile-bottom-menu">
            {Array.isArray(mobileBottomMenu) && mobileBottomMenu.length > 0 && mobileBottomMenu.map((element, idx) => (
                <div key={element?.key || idx}>
                    {setMenuElement(element, element?.key)}
                </div>
            )
            )}
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobileBottomMenu;