import { Props as ButtonProps } from 'components/layout/Button';

import Component from './component';

export interface Props {
    sections?: Section[];
    onSubmenuClick?: (key?: string) => any;
    layout?: Layout;
}

export interface Section {
    key: string;
    type: SectionType;
    title: string;
    elements?: SectionElement[];
}

export interface SectionElement {
    key: string;
    href?: string;
    title?: string;
    subTitle?: string;
    icon?: string;
    alt?: string;
    buttonProps?: ButtonProps;
}

export enum SectionType {
    Promoted = 'promoted',
    Default = 'default',
}

export enum Layout {
    Default = 'default',
    Wide = 'wide',
}

Component.defaultProps = {
    sections: [],
    onSubmenuClick: () => null,
    layout: Layout.Default,
};
export default Component;