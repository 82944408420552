import { FunctionComponent } from 'react';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutLogo: FunctionComponent<Props> = ({ logo, logoAlt }) => ((
    <StyledComponent
        className="layout-logo"
    >
        <img
            className="logo-img"
            src={logo}
            width="300"
            height="44"
            alt={logoAlt}
            loading="lazy"
        />
    </StyledComponent>
));

export default LayoutLogo;