import styled from '@emotion/styled';

export default styled.div`
    position: relative;
    width: 100%;
    display: flex;
    background: white;
    border-radius: .5em;
    /* box-shadow: rgb(3 27 78 / 10%) 0px .2em .4em; */
    overflow: hidden;

    .sub-menu-section {
        .sub-menu-section-title {
            margin-bottom: 1.5em;
            color: #121212;
            font-weight: 600;
            text-transform: uppercase;
        }

        .sub-menu-section-elements {
            width: 100%;
            min-width: 22em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .sub-menu-section-element {
                width: 100%;
                font-size: 1em;
                text-align: center;

                .element-header {
                    padding: 2em 0;

                    .sub-menu-section-element-title {
                        font-weight: 400;
                        color: #121212;
                        display: block;
                        font-size: .83em;
                    }

                    .sub-menu-section-element-sub-title {
                        font-weight: 400;
                        color: #121212;
                        font-size: 80%;
                        display: block;
                        margin-top: .3em;
                    }

                    .layout-button {
                        padding-top: 1em;
                    }
                }

                .sub-menu-section-element-icon {
                    display: none;
                    position: absolute;
                    right: 1em;
                    transform: translateY(-50%);
                    top: 50%;;
                    color: #00AEEF;
                    align-self: flex-end;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    background-color: #000000;
                    .sub-menu-section-element-title {
                        color: #FFFFFF;
                    }
                }
            }
        }

        &:last-of-type {
            border-right: none;
        }

        &.type-promoted {
            background-color: #F6FDFF;
            min-width: 14em;

            .sub-menu-section-title {
                color: #00AEEF;
                margin-bottom: 1.5em;
            }

            .sub-menu-section-element {
                .sub-menu-section-element-title {
                    font-weight: 600;
                }
                .sub-menu-section-element-sub-title {

                }
            }
        }
    }

    &.layout-wide {
        flex-wrap: wrap;
        width: 46em;
    }
`;